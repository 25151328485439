import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import Header from '../components/header'
import BackgroundImage from 'gatsby-background-image'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import Img from "gatsby-image"
import NavMobile from "../components/nav_mobile"

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <NavMobile />
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <Header />
            <BackgroundImage className="layout title__48 banner__background" fluid={entry.frontmatter.background.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__container">
                    <div className="layout__title banner center">
                        <h1>{entry.frontmatter.heading}</h1>
                    </div>
                </div>
            </BackgroundImage>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="breadcrumb breadcrumb__top">
                            <p><Link to="/">Home</Link> &nbsp;&rsaquo;&nbsp; <Link to="/background-checks/">Background checks</Link> &nbsp;&rsaquo;&nbsp; <strong>{entry.frontmatter.title}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__2-1 grid__gap--48">
                            <div className="text">
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <Link to="/contact/" className="btn btn__margin btn__blue btn__small">Make enquiry &rsaquo;</Link>
                            </div>
                            <figure>
                                <Img fluid={entry.frontmatter.image.childImageSharp.fluid} alt={entry.frontmatter.heading} /> 
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
				meta_description
                heading
                background {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                image {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`